import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config";



const initialState = {
   
    loading: false,
    response: null,
    educationList:null
    
}



//GET-EDUCATION-LIST-----------------------------------------------------------start>
export const getEducationList = createAsyncThunk(
    "getEducationList",
    async () => {
        try {
            const response = await instance.get("/remark/education/education-list")
            return response?.data;
        } catch (error) {
            throw error
        }
    }
)
//GET-EDUCATION-LIST-----------------------------------------------------------end>



export const EducationSlice = createSlice({
    name: "EducationSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        
        builder.addCase(getEducationList.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getEducationList.fulfilled, (state, action) => { 
            state.loading = false;
            state.educationList = action?.payload; 
        });
        builder.addCase(getEducationList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
    }
})

export default EducationSlice.reducer;