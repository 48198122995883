import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"

const initialState = {
    data: null,
    loading: false,
    response: null,
}

export const getShortlistList = createAsyncThunk(
    "getShortlistList",
    async (body) => {
        try {
            const response = await instance.get(`/remark/shortlist/fetch-shortlist?page=${body.page}&search=${body.search}&filter=${body.filter}`,{})
            return response.data;
        } catch (error) {
            throw error
        }
    }
)



export const ShortlistSlice = createSlice({
    name: "ShortlistSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getShortlistList.pending, (state, action) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(getShortlistList.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.data = action.payload;
        });
        builder.addCase(getShortlistList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
    
    }
})

export default ShortlistSlice.reducer;