import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"
import axios from "axios"

const initialState = {
    data: null,
    loading: false,
    status: null,
    response: null,
    companyLogoUploadStatus:null,
    logoLoading:false
}



export const getCompanyList = createAsyncThunk(
    "getCompanyList",
    async (body) => {
        try {
            const response = await instance.get(`/remark/company/fetch-all?page=${body.page}&search=${body.search}`)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)
//get-all-company list without pagination check exit company or not ---------------------------------------
export const getAllCompanyList = createAsyncThunk(
    "getAllCompanyList",
    async (data) => {
        // debugger;
        try {
            
            const response = await instance.get(`/remark/company/fetch-all-company-list?comp_name=${data}`)
            return response?.data;
        } catch (error) {
            throw error
        }
    }
)

//Add-company------param
export const addCompany = createAsyncThunk(
    "addCompany",
    async (body) => {
        try {
           
            const response = await instance.post(`/remark/company/add-company`,body)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)
export const updateCompanyDetailsAction = createAsyncThunk(
    "updateCompanyDetailsAction",
    async (body) => {
        try {
            const response = await instance.post(`/remark/company/update-company?id=${body.id}`,body.data)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

//update company logo====

export const updateCompanyLogo = createAsyncThunk(
    "updateCompanyLogo",
    async (body) => {
        try {
            console.log(body,"booo")
            const response = await axios.post("https://testapi.remarkhr.com/others/upload-company-logo",body)
            return response.data;
           

        } catch (error) {
            throw error
        }
    }
)


//Employer profile picture upload---------------------------

export const uploadEmployerProfilePicture = createAsyncThunk(
    "uploadEmployerProfilePicture",
    async (body) => {
        try {
            console.log(body,"booo")
            const response = await axios.post("https://testapi.remarkhr.com/others/upload-user-photo",body)
            return response.data;

        } catch (error) {
            throw error
        }
    }
)

export const CompanySlice = createSlice({
    name: "CompanySlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCompanyList.pending, (state, action) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(getCompanyList.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.data = action.payload;
        });
        builder.addCase(getCompanyList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
      
        builder.addCase(updateCompanyDetailsAction.pending, (state, action) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(updateCompanyDetailsAction.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.response = action.payload;
        });
        builder.addCase(updateCompanyDetailsAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        builder.addCase(updateCompanyLogo.pending, (state, action) => {
            state.logoLoading = true;
          
            state.response = null;
        });
        builder.addCase(updateCompanyLogo.fulfilled, (state, action) => {
            state.logoLoading = false;
            state.response = action.payload;
        });
        builder.addCase(updateCompanyLogo.rejected, (state, action) => {
            state.logoLoading = false;
            state.error = action.payload;
        })
        builder.addCase(uploadEmployerProfilePicture.pending, (state, action) => {
            state.logoLoading = true;
            state.response = null;
        });
        builder.addCase(uploadEmployerProfilePicture.fulfilled, (state, action) => {
            state.logoLoading = false;
            state.response = action.payload;
        });
        builder.addCase(uploadEmployerProfilePicture.rejected, (state, action) => {
            state.logoLoading = false;
            state.error = action.payload;
        })
    
    }
})

export default CompanySlice.reducer;