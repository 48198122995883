import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"


const initialState = {
   
    loading: false,
    // status: null,
    response: null,
    
}

// export const getJobs = createAsyncThunk(
//     "GetJobs",
//     async (body) => {
//         try {
//             const response = await instance.get(`/remark/job/fetch-jobs?page=${body.page}&search=${body.search}`)
//             return response.data;
//         } catch (error) {
//             throw error
//         }
//     }
// )

//GET-LEAD-LIST-----------------------------------------------------------start>
export const getRemarkLeadList = createAsyncThunk(
    "getRemarkLeadList",
    async (body) => {
        try {
            const response = await instance.get(`/remark/lead/remark-lead-list?page=${body.page}&search=${body.search}&filter=${body.filter}`)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)
//GET-LEAD-LIST-----------------------------------------------------------end>

//CALL-COUNT-UPDATE-----------------------------------------------------------start>

export const remarkCallCountUpdate = createAsyncThunk(
    "remarkCallCountUpdate",
    async (id) => {
        try {
            const response = await instance.get(`/remark/lead/remark-call-count-update?id=${id}`);
            return response?.data
        } 
        catch (error) {
            return error
        }
    }
)
//CALL-COUNT-UPDATE-----------------------------------------------------------end>

export const RemarkLeadSlice = createSlice({
    name: "RemarkLeadSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        

        builder.addCase(getRemarkLeadList.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getRemarkLeadList.fulfilled, (state, action) => {
          
            state.loading = false;
            state.response = action?.payload; 
        });
        builder.addCase(getRemarkLeadList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

    }
})

export default RemarkLeadSlice.reducer;