import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config";



const initialState = {
    data: null,
    loading: false,
    error: null,
    // interviewResponse: null,
}



export const applyLeave = createAsyncThunk(
    "applyLeave",
    async (body) => {
        try {
            const res = await instance.post("/crm/leave/apply-leave", body)
            return res?.data
        } catch (error) {
            throw error
        }   
    }
)

export const leaveList = createAsyncThunk(
    "leaveList",
    async () => {
        try {
            const res = await instance.get("/crm/leave/fetch-leave")
            return res?.data
        } catch (error) {
            throw error
        }
    }
)


export const LeaveStatusUpdate = createAsyncThunk(
    "LeaveStatusUpdate",
    async (data) => {
      try {
      //   debugger;
        const response = await instance.post(`/crm/leave/update-leave-status?id=${data.lv_id}`,data)
        return response?.data
      //   return{loading:"loading",response:"successfully"}
      } catch (error) {
        throw error;
      }
    }
  );


export const LeaveSlice = createSlice({
    name: "LeaveSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(applyLeave.pending, (state) => {
            state.loading = true;
            // state.interviewResponse = null;
            state.data = null;
        });
        builder.addCase(applyLeave.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(applyLeave.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

    }
})

export default LeaveSlice.reducer;