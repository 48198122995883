import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../config/Config";

const initialState = {
    loading: false,
    response: null,
    error: null,
    taskList: null,
    tickettask: null,
    singleTask: null,
}

export const GetTaskList = createAsyncThunk(
    "GetTaskList",
    async (body, { rejectWithValue }) => {
        try {
            const response = await instance.get(`/crm/tasks/list-all?page=${body.page}&search=${body.search}&fStartDate=${body.fStartDate}&fEndDate=${body.fEndDate}&fStatus=${body.fStatus}&fSortBy=${body.fSortBy}`);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error?.response)
        }
    })

export const GetTask = createAsyncThunk("GetTask", async (id, { rejectWithValue }) => {
    try {
        const response = await instance.get(`/crm/tasks/get-task?id=${id}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error?.response);
    }
})

export const fetchAllTicketTask = createAsyncThunk("fetchAllTicketTask", async (_, { rejectWithValue }) => {
    try {
        const response = await instance.get("/crm/tasks/list-ticket-task");
        return response?.data;
    } catch (error) {
        return rejectWithValue(error?.response || { message: "Something went wrong" });
    }
})

export const CreateTask = createAsyncThunk(
    "CreateTask",
    async (body, { rejectWithValue }) => {
        const formData = new FormData();
        formData.append("task_assigned_to", body.task_assigned_to);
        formData.append("task_description", body.task_description);
        formData.append("task_end_at", body.task_end_at);
        formData.append("task_started_at", body.task_started_at);
        formData.append("task_title", body.task_title);
        formData.append("task_status", body.task_status);
        try {
            const response = await instance.post("/crm/tasks/create-task", formData);
            return response?.data
        } catch (error) {
            return rejectWithValue(error.response)
        }
    }
)
export const CommentOnTicketTask = createAsyncThunk(
    "CommentOnTicketTask",
    async (body, { rejectWithValue }) => {
        
        const formData = new FormData();
        formData.append("ticket_tsk_comment", body?.ticket_tsk_comment);
        formData.append("ticket_tsk_id", body?.ticket_tsk_id);
      
        try {
           
            const response = await instance.post("/crm/tasks/comment-ticket-task", formData);
            return response?.data
        } catch (error) {
            return rejectWithValue(error.response)
        }
    }
)

//create-ticket-task-------
export const assignTicketTask = createAsyncThunk(
    "assignTicketTask",
    async (body, { rejectWithValue }) => {
        // debugger;
       

        const formData = new FormData();
        // formData.append("tsk_subject", body.t_subject);
        // formData.append("tsk_description", body.t_description);
        // formData.append("tsk_project_name", body.t_project_name);
        // formData.append("tsk_project_type", body.t_project_type);
        // formData.append("tsk_priority", body.t_priority);
        formData.append("tsk_assigned_to", body.tsk_assigned_to);
        formData.append("tsk_subject", body.tsk_subject);
        try {
            const response = await instance.post(`/crm/tasks/update-ticket-task?id=${body?.tsk_id}`, formData);
            return response?.data
        } catch (error) {
            return rejectWithValue(error.response)
        }
    }
)
export const CreateTicketTask = createAsyncThunk(
    "CreateTicketTask",
    async (body, { rejectWithValue }) => {
       

        // const formData = new FormData();
        // formData.append("tsk_subject", body.t_subject);
        // formData.append("tsk_description", body.t_description);
        // formData.append("tsk_project_name", body.t_project_name);
        // formData.append("tsk_project_type", body.t_project_type);
        // formData.append("tsk_priority", body.t_priority);
        // formData.append("tsk_assigned_to", body.t_assigned_to);
        // formData.append("tsk_assigned_by", body.t_assigned_by);
        // formData.append("tsk_doc", body.tsk_doc);
        try {
            const response = await instance.post("/crm/tasks/create-ticket-task", body);
            return response?.data
        } catch (error) {
            return rejectWithValue(error.response)
        }
    }
)

export const updateStatusTicketTask = createAsyncThunk("updateStatusTicketTask",
    async (body, { rejectWithValue }) => {
        // debugger;
        const formData = new FormData();
        formData.append("tsk_id", body.tsk_id);
        formData.append("tsk_status", body.tsk_status);
      
       
        try {
           
            const response = await instance.post(`/crm/tasks/update-ticket-task-status?tsk_id=${body?.tsk_id}`, formData);
            return response?.data
        } catch (error) {
            debugger;

            return rejectWithValue(error?.response)
        }

    })
export const UpdateTask = createAsyncThunk("UpdateTask",
    async (body, { rejectWithValue }) => {
        const formData = new FormData();
        formData.append("task_end_at", body.data.task_end_at);
        formData.append("task_status", body.data.task_status);
        formData.append("task_title", body.data.task_title);
        formData.append("task_started_at", body.data.task_started_at);
        formData.append("task_description", body.data.task_description);
        try {
            const response = await instance.post(`/crm/tasks/update-task?id=${body?.id}`, formData);
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response)
        }

    })

export const UpdateStatus = createAsyncThunk(
    "UpdateStatus",
    async (body, { rejectWithValue }) => {
        try {
            const response = await instance.get(`/crm/tasks/update-status?id=${body?.id}&status=${body?.status} `);
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response)
        }

    })

export const TaskSlice = createSlice({
    name: "TaskSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(GetTaskList.pending, (state) => {
            state.loading = true;
            state.response = null;
            state.singleTask = null;
        });
        builder.addCase(GetTaskList.fulfilled, (state, action) => {
            state.loading = false;
            state.taskList = action.payload;
        });
        builder.addCase(GetTaskList.rejected, (state, action) => {
            state.loading = false;
            state.status = action.status?.data?.status;
            state.error = action.payload?.data;
        });

        builder.addCase(GetTask.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(GetTask.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.singleTask = action.payload;
        });
        builder.addCase(GetTask.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(CreateTask.pending, (state) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(CreateTask.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.response = action.payload;
        });
        builder.addCase(CreateTask.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.data;
        })

        builder.addCase(UpdateTask.pending, (state) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(UpdateTask.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
        });
        builder.addCase(UpdateTask.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.data;
        })

        builder.addCase(UpdateStatus.pending, (state) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(UpdateStatus.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
        });
        builder.addCase(UpdateStatus.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.data;
        })

        builder.addCase(CreateTicketTask.pending, (state) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(CreateTicketTask.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.response = action.payload;
        });
        builder.addCase(CreateTicketTask.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.data;
        })
      



    }

})

export default TaskSlice.reducer;